.footer {
  padding-top: 2%;
  padding-bottom: 2%;
  background: url("../../assets/FooterBackground.png");
  background-color: #000000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.footerContent {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  justify-items: flex-start;
  margin-left: 80px;
  margin-right: 80px;
}

.footerLogos img {
  width: 60px;
}

.footerLogos img:hover {
  cursor: pointer;
}
.footerContact {
  text-align: right;
  color: #ffffff;
}

@media (max-width: 700px) {
  .footerContent {
    flex-direction: column;
    column-gap: 5%;
  }

  .footerContact {
    text-align: left;
  }
}
